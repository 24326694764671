import { Link } from "gatsby"
import React from "react"


import Layout from '../components/layout'
import SEO from '../components/seo'

const Privacy = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <main>
        <div className="l-privacy l-wrap">
          <div className="l-privacy__lead">
            <p>
            「名言アドバイザー」をご覧いただき誠にありがとうございます。<br />
              訪問者様とのより良い関係を築くために、以下に当サイトのプライバシーポリシーを記載します
            </p>
          </div>

          <dl className="l-privacy__item">
            <dt className="l-privacy__hdg">個人情報の管理</dt>
            <dd className="l-privacy__text">個人情報を取り扱うにあたっては、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。</dd>
          </dl>

          <dl className="l-privacy__item">
            <dt className="l-privacy__hdg">個人情報の第三者への開示</dt>
            <dd className="l-privacy__text">
              <p>当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
              <ul>
                <li>本人のご了解がある場合</li>
                <li>法令に基き、開示が必要となる場合</li>
              </ul>
            </dd>
          </dl>

          <dl className="l-privacy__item">
            <dt className="l-privacy__hdg">当サイトが使用しているアクセス解析ツールについて</dt>
            <dd className="l-privacy__text">
              <p>
                当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br />
                このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。<br />
                このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
              </p>
              <p>
                Cookieを無効にし、収集を拒否することも出来ますので、お使いのブラウザの設定をご確認ください。詳しくは<Link to="https://marketingplatform.google.com/about/analytics/terms/jp/">こちら</Link>をご参照ください。
              </p>
            </dd>
          </dl>

          <dl className="l-privacy__item">
            <dt className="l-privacy__hdg">当サイトの広告について</dt>
            <dd className="l-privacy__text">
              <p>
                当サイトでは「Amazonアソシエイト」「楽天アフィリエイト」「A8net」などのアフィリエイトプログラムや第三者配信広告サービス「GoogleAdsense」を利用しています。
              </p>
              <p>
                GoogleAdsenseでは広告配信プロセスにおいてデータを収集するために、Cookieを使用しています。GoogleでCookieを使用することにより、インターネットにおけるご自身のサイトや他のサイトへのアクセス情報に基づいてユーザーに広告を配信することが可能になります。
              </p>
              <p>
                Cookieを使用しないように設定するには<Link to="https://policies.google.com/technologies/ads?hl=ja">こちら</Link>をご参照ください。
              </p>
              <p>
                また、当サイトはamazon.co.jpなどを宣伝し、リンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイト宣伝プログラムである「Amazonアソシエイト・プログラム」の参加者です。
              </p>
            </dd>
          </dl>

          <dl className="l-privacy__item">
            <dt className="l-privacy__hdg">著作権について</dt>
            <dd className="l-privacy__text">
              <p>当サイトに掲載されている情報についての著作権は放棄しておりません。</p>
              <p>著作権法により認められている引用の範囲である場合を除き「内容、テキスト、画像等」の無断転載・使用を固く禁じます。</p>
            </dd>
          </dl>

          <dl className="l-privacy__item">
            <dt className="l-privacy__hdg">免責事項</dt>
            <dd className="l-privacy__text">
              <p>当サイトに掲載されている情報に関しては、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。</p>
              <p>また、当サイトには外部サイトへのリンクが含まれています。これらの外部サイトのコンテンツに対して一切責任を負いません。</p>
              <p>掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>
            </dd>
          </dl>
        </div>

      </main>
    </Layout>
  )
}



export default Privacy